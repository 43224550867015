/* Csr.css */

/* General styles */
.project-item {
    margin-bottom: 20px;
    animation: fadeIn 1s ease-in-out; /* Animation for fading in */
  }
  
  .project-content {
    display: flex;
    flex-direction: row; /* Change to column on smaller screens */
    align-items: center;
    gap: 10px;
  }
  
  .project-title {
    text-align: center;
    font-size: 1.5rem;
    margin: 10px 0;
  }
  
  .project-image-container {
    flex: 1;
    max-width: 100%;
  }
  
  .project-image {
    width: 100%;
    height: auto;
    max-height: 300px; /* Adjust as needed */
    object-fit: cover;
    border-radius: 8px;
  }
  
  .project-text {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .project-description {
    font-size: 1rem;
    margin-bottom: 10px;
    overflow: hidden; /* Hide overflow if necessary */
  }
  
  .see-more-link {
    display: inline-block;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  .see-more-link:hover {
    text-decoration: underline;
  }
  
  .banner {
    text-align: center;
    padding: 20px;
    color: #ffffff;
    background-color: #0d1f35;
  }
  .project-item.left .project-content {
    flex-direction: row;
  }
  
  .project-item.right .project-content {
    flex-direction: row-reverse;
  }
  .info-section {
    margin: 20px;
  }
  
  .projects-section {
    margin: 20px;
  }
  
  @media (max-width: 768px) {
    .project-content {
      flex-direction: column; /* Stack image and text vertically on smaller screens */
    }
  
    .project-image-container, .project-text {
      width: 100%;
      padding: 0;
    }
  }
  
  /* Keyframes for animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  