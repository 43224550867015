/* Recent Articles Styles */
.article-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .article-card img {
    max-width: 100%;
    border-radius: 8px;
  }
  
  .article-card h4 {
    margin: 15px 0 10px;
    font-size: 1.25rem;
  }
  
  .article-card p {
    font-size: 0.875rem;
    color: #555;
  }
  
  .article-card a {
    color: #007bff;
    text-decoration: none;
  }
  
  .article-card a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .article-card {
      padding: 10px;
    }
  
    .article-card h4 {
      font-size: 1.1rem;
    }
  
    .article-card p {
      font-size: 0.8rem;
    }
  }
  