/* src/List.css */
.carousel-item {
  padding: 0 15px; /* Add some horizontal padding for better alignment */
}

.carousel-item .row {
  margin-left: 0;
  margin-right: 0;
}

.card-img-top {
  height: 200px; /* Adjust height for consistency */
  object-fit: cover;
}

/* Responsive adjustments */
@media (min-width: 992px) {
  .carousel-item .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .carousel-item .col-md-3 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: 767px) {
  .carousel-item .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
