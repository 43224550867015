.details {
  padding: 20px;
}


.img-fluid{
  
    max-width: 100%; /* Scale to fit within the screen width */
    max-height: 80vh; /* Set a maximum height to 80% of the viewport height */
    height: auto;    /* Maintain aspect ratio */
    display: block;  /* Remove any space below the image */
    margin: 0 auto;  /* Center the image */


}
.title {
  font-family: 'Georgia', serif;
  font-size: 2.5rem;
  color: #333;
}

.detail-description {
  font-family: 'Arial', sans-serif;
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  text-align: justify;
  margin: 0 15px;
}

.gallery-image {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


  
 
 
  