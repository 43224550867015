
  .performance-dashboard {
    padding: 2rem;
    max-width: 1200px;
    margin: 0;
  }
  
  h3 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
 
    text-align: center;
  }

 
  p {
    font-size: 1rem;
    color: #666;
    margin-top: 10px;
  }
  
  .line-chart, .pie-chart {
    max-width: 100%;
    height: auto;
  }
  
  .col-lg-4, .col-lg-6 {
    flex: 0 1 30%;
  }
  
  @media (max-width: 768px) {
    .col-lg-4, .col-lg-6 {
      flex: 0 1 100%;
    }
  }
  .hover-card {
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .hover-card .hover-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #000;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .hover-card:hover .hover-effect {
    opacity: 1;
  }
  .card-body{
height: auto;
  }