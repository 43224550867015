.video-gallery {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .gallery-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333; /* Dark text color for better readability */
  }
  
  .video-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
  }
  
  .video-item {
    position: relative;
    width: calc(33.333% - 15px);
    margin-bottom: 15px;
    cursor: pointer;
    overflow: hidden;
  }
  
  .video-item a {
    text-decoration: none;
    color: inherit;
  }
  
  .video-thumbnail {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
  }
  
  .video-item:hover .video-thumbnail {
    transform: scale(1.05);
  }
  
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .video-item:hover .video-overlay {
    opacity: 1;
  }
  
  .video-title {
    color: black; /* White text color for the overlay */
    font-size: 1rem;
    text-align: center;
    padding: 10px;
    max-width: 80%;
  }
  
  /* Responsive Styles */
  @media (max-width: 1200px) {
    .video-item {
      width: calc(50% - 15px);
    }
  }
  
  @media (max-width: 768px) {
    .video-item {
      width: calc(100% - 15px);
    }
  }
  
  @media (max-width: 576px) {
    .gallery-title {
      font-size: 1.5rem;
    }
  
    .video-title {
      font-size: 0.9rem;
    }
  }
  