.container {
  margin: 0;
  padding: 0;
}

.header {
  background: transparent;
  padding: 20px 10px;
  box-shadow: none;
  display: flex;
  justify-content: center;
}

.btn-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.btn-custom {
  border: 1px solid #ddd;
  border-radius: 20px;
  padding: 5px 20px;
  background: transparent;
  color: #007bff;
  transition: color 0.3s, border-color 0.3s;
}

.btn-custom:hover,
.btn-custom:active {
  border-color: #007bff;
  color: #007bff;
}

.sort-icon {
  margin-right: 5px;
}

.sort-text {
  color: white;
}

.project-type-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.project-type-buttons .btn-custom {
  background: #f8f9fa;
  color: #343a40;
}

.project-type-buttons .btn-custom:hover,
.project-type-buttons .btn-custom:active {
  background: #343a40;
  color: #f8f9fa;
}

.custom-component {
  background-color: #f8f9fa; /* Bootstrap's light gray */
}

.image-container-f {
  position: relative;
  overflow: hidden;
}

/* Fixed height class */
.fixed-height {
  height: 400px; /* Adjust this value to the desired height */
  object-fit: cover; /* Ensures the image covers the container without stretching */
}

/* Overlay styles */
.text {
  color: #ddd;
  padding: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Adds some shadow to make text stand out */
}
