/* Chairman's Message Section */
.chairmansMessage {
    background-color: #f0f0f0;
    padding: 4rem 1rem;
    text-align: center;
}

.chairmansMessageContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.chairmanImageContainer {
    margin-bottom: 20px;
}

.chairmanImage {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    border: 5px solid #ffc107;
}

.chairmansMessage h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.chairmansMessage p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #333;
}

.chairmansMessage h4 {
    margin-top: 1.5rem;
    font-size: 1.5rem;
    color: #555;
}

.corporateManagement {
    padding: 4rem 1rem;
    background-color: #fff;
    text-align: center;
}

.corporateManagementContainer {
    max-width: 1200px;
    margin: 0 auto;
}

.corporateManagementTitle {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #333;
}


.managementList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
}

.managementItem {
    text-align: center;
    width: calc(33.333% - 2rem);
}

.managementItem img {
    width: 150px; 
    height: 150px; 
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    border: 3px solid #ffc107;
}

.managementItem h4 {
    font-size: 1.3rem;
    margin-top: 10px;
    color: #333;
}

.managementItem p {
    font-size: 1.1rem;
    color: #555;
}


@media (max-width: 768px) {
    .managementItem {
        width: calc(50% - 2rem); 
    }

    .managementItem img {
        width: 130px; 
        height: 130px;
    }
}

@media (max-width: 576px) {
    .managementItem {
        width: 100%; 
    }

    .managementItem img {
        width: 120px; 
        height: 120px;
    }

    .chairmanImage {
        width: 150px;
        height: 150px;
    }

    .chairmansMessage p {
        font-size: 1rem;
    }
}



.successTimeline {
    background-color: #f9f9f9;
    padding: 4rem 0;
}

.timelineContainer {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
}

.timelineTitle {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 40px;
}

.timeline {
    position: relative;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.timeline::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 6px;
    background: #ffc107;
    left: 50%;
    margin-left: -3px;
}

.timelineItem {
    position: relative;
    padding: 20px 40px;
    width: 50%;
    box-sizing: border-box;
}

.timelineItem:nth-child(odd) {
    left: 0;
    text-align: right;
}

.timelineItem:nth-child(even) {
    left: 50%;
    text-align: left;
}

.timelineItem::before {
    content: '';
    position: absolute;
    top: 30px;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border: 4px solid #ffc107;
    border-radius: 50%;
    z-index: 1;
}

.timelineItem:nth-child(odd)::before {
    right: -12px;
}

.timelineItem:nth-child(even)::before {
    left: -12px;
}

.timelineContent {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.timelineContent h4 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 0 10px;
    color: #333;
}

.timelineContent p {
    font-size: 1.2rem;
    margin: 0;
    color: #555;
}

.timelineContent i {
    font-size: 1.5rem;
    color: #ffc107;
    margin-right: 10px;
}


@media (max-width: 768px) {
   
    .timeline::before {
        left: 10px; 
    }

    .timelineItem {
        width: 100%;
        padding-left: 30px; 
        padding-right: 20px;
        text-align: left !important;
    }

    .timelineItem:nth-child(odd),
    .timelineItem:nth-child(even) {
        left: 0; 
    }

    .timelineItem::before {
        top: 20px;
        left: -15px; 
        right: auto;
    }
}
