footer {
    padding: 2rem 0;
  }
  
  footer h5 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  
  footer p {
    margin: 0.5rem 0;
  }
  
  .social-icons a {
    font-size: 1.5rem; /* Adjust size as needed */
    transition: color 0.3s;
  }
  
  .social-icons a[href*="facebook"]:hover {
    padding: 0.2rem;
    background-color: #3b5998;
    color: #fff;
  }
  a[href*="instagram"]:hover {
    padding: 0.2rem;
    background: linear-gradient(45deg, #F58529, #DD2A7B, #8134AF, #515BD4); /* Correct Instagram gradient */
    color: #fff;
  }
  a[href*="youtube"]:hover {
    padding: 0.2rem;
    background-color: #FF0000;
    color: #fff;
  }
  
  a[href*="linkedin"]:hover {
    padding: 0.2rem;
    background-color: #0077B5; /* LinkedIn's brand color */
    color: #fff;
  }
  
  iframe {
    border-radius: 8px;
  }
  
  @media (max-width: 767.98px) {
    .social-icons {
      text-align: center;
    }
  }
  .copyrigth{
    text-align: center;
  }
  