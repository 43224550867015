body{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}
.styled-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d1f35; /* Royal blue background */
  color: white; /* White text color for contrast */
  padding: 70px 15px; /* Adjust padding as needed */
  width: 100%; /* Full width of the viewport */
  box-sizing: border-box; /* Include padding in the width calculation */
}

.home-content {
  text-align: center; /* Center-align text inside the content div */
}

.left-part {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem; /* Add some spacing between titles */
}

.right-part {
  font-size: 1.2rem;
  margin-top: 0; /* Remove default top margin if necessary */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .left-part {
    text-align: center; /* Center align for smaller screens */
  }

  .right-part {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
}

.box-container {
    margin: 0 auto;
    padding: 2rem 1rem;
}

.box {
    border: 1px solid #ddd;
   
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.box-icon {
    font-size: 3rem;
    color: #000;
}
.box-icon:hover {
  
    color: #ffc107;
}
.box-heading {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
}

.box-description {
    font-size: 1rem;
    color: #333;
}

.main-banner {
    background-color: #f8f9fa;
    padding: 3rem 1rem;
}

.video-section iframe {
    width: 100%;
    height: 315px;
}

.project-item img,
.corporate-item img {
    width: 100%;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.testimonials blockquote {
    font-size: 1.2rem;
}

.testimonials-section {
    background-color: #f8f9fa;
    padding: 3rem 1rem;
}

/* Container Styles */
.circle-container {
    display: flex;
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    justify-content: space-evenly; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    padding: 20px;
    width: 100%;
    height: 100vh; /* Full viewport height */
    color: #f8f9fa;
    background-color: #0d1f35; /* Background color for visibility */
  }
  
  /* Circle Styles */
  .circle {
    width: 100%;
    max-width: 450px; /* Maximum width of the circle */
    height: auto; /* Height adjusts based on width */
    overflow: hidden;
    display: flex; /* Ensures image fits the container */
  }
  
  .circle-image {
    width: 100%;
    height: auto; /* Maintains aspect ratio */
    object-fit: cover;
  }
  
  /* Text Styles */
  .right-part {
    color: #f8f9fa;
    text-align: center;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .circle-container {
      flex-direction: column; /* Stack vertically on small screens */
      height: auto; /* Height adjusts based on content */
    }
    
    .circle {
      max-width: 80%; /* Adjust circle size on smaller screens */
    }
  }
  


  .about-section {
    background-image: url('img\ (3).JPG'); /* Replace with your image URL */
    background-size: cover;
    background-position: center;
    height: 100vh; 
    background-attachment: fixed; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .text-container {
    background-color: rgba(255, 255, 255, 0.8); /* White background with reduced opacity */
    padding: 20px;
    max-width: 800px; /* Max width for larger screens */
    width: 90%; /* Takes most of the section width, adjusted for smaller screens */
    text-align: center;
    box-sizing: border-box; /* Ensure padding is included in width calculation */
  }
  
  @media (max-width: 768px) {
    .text-container {
      width: 95%; /* Adjust width for tablets and smaller devices */
      max-width: 100%; /* Full width for smaller devices */
      padding: 10px; /* Reduce padding on smaller devices */
    }
  }
  
  @media (max-width: 576px) {
    .text-container {
      width: 100%; /* Full width for small devices */
      max-width: 100%; /* Full width */
      padding: 5px; /* Further reduce padding on very small devices */
    }
  }
 
  /* Optional: Ensure that text inside the container is responsive */
  .text-container p {
    margin-bottom: 1rem; /* Space between paragraphs */
    line-height: 1.5; /* Improve readability */
  }
  /* Philosophy Section Styling */
.Philosophy {
  background-color: #0d1f35; /* Light background for contrast */
  padding: 20px; /* Padding for spacing */
  border-radius: 8px; /* Rounded corners */
  margin:40px;
}

.Philosophy h2 {
  font-size: 2.5rem; /* Larger font size for headings */
  color: #fff; /* Dark text color for better readability */
}

.Philosophy h4 {
  font-size: 1.5rem; /* Slightly larger font for sub-headings */
  color: #fff; /* Consistent color */
  margin-bottom: 1rem; /* Space below sub-headings */
}

.Philosophy p {
  font-size: 1rem; /* Adjust font size for better readability */
  color: #fff; 
/* Lighter text color for paragraphs */
  line-height: 1.6; /* Increase line height for readability */
}

/* Vertical Line Styling */
.vertical-line {
  border-left: 2px solid #ddd; /* Light vertical line */
  height: 100%; /* Full height of the container */
}

/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .Philosophy h2 {
    font-size: 2rem; /* Adjust heading size for smaller screens */
  }

  .Philosophy h4 {
    font-size: 1.25rem; /* Adjust sub-heading size for smaller screens */
  }

  .Philosophy p {
    font-size: 0.9rem;
    /* Adjust paragraph font size for smaller screens */
  }

  .vertical-line {
    display: none; /* Hide vertical line on smaller screens */
  }
}

@media (min-width: 769px) {
  .Philosophy .row {
    display: flex;
    align-items: center;
  }

  .Philosophy .col-md-1 {
    margin: 0 20px; /* Margin between sections */
  }
}

  
 
  