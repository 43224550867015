/* Apply Poppins font across the website */
body {
    font-family: 'Poppins', sans-serif;
  }
  
  /* Navbar Link Style */
  .nav-link {
    font-weight: bold;
    font-size: large;
    font-family: serif;
  }
  
  /* Hover Effect for Navbar Links */
  .nav-link:hover {
    color: #ffc107;
  }
  
  /* Custom Styles for the Brand Text */
  .navbar-brand span {
    font-family: 'Poppins', serif;
    font-size: 1.8rem;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
  }
  
  /* Ensure that the Invest Today button has the correct warning color */
  .btn-warning {
    background-color: #ffc107;
    color: #212529;
    border: none; /* Remove border if needed */
  }
  
  .btn-warning:hover {
    background-color: #e0a800; /* Slightly darker shade for hover effect */
  }
  /* Header.css */

@media (max-width: 768px) {
  .navbar {
    position: relative !important; /* Disable sticky behavior on mobile */
  }
}
