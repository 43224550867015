/* src/global.css */
body {
    font-family:  serif; 
    overflow-x: hidden;/* Poppins with serif fallback */
    * {
        box-sizing: border-box;
    }
    
    html, body {
        overflow-x: hidden;
    }
    
}
