.image-text-slider {
  position: relative;
  width: 100%;
  height: 100vh; /* Full height for larger screens */
  overflow: hidden;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1;
}

.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s ease-in-out;
}

.text-overlay {
  position: absolute;
  background-color: none;
  top: 30%;
  left: 5%;
  transform: translateY(-50%);
  text-align: left;
  color: white; 
  padding: 20px;
  opacity: 0;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  z-index: 2; 
  width: 50%; 
}

.text-overlay.show {
  opacity: 1;
  transform: translateY(0); 
}

.main-text {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 1.2rem;
  color: white;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .image-text-slider {
    height: 50vh; /* Adjust the height for smaller screens */
  }

  .text-overlay {
    width: 70%; /* Increase width on smaller screens */
    left: 10%; /* Center text more */
    top: 10%; /* Adjust vertical position */
  }

  .main-text {
    font-size: 1.5rem; /* Smaller text size on tablets */
  }

  .description {
    font-size: 1rem; /* Smaller description text */
  }
}

@media (max-width: 480px) {
  .image-text-slider {
    height: 70vh; /* Adjust height for mobile */
  }

  .text-overlay {
    width: 90%;
    left: 5%;
    top: 30%; /* Adjust for mobile */
  }

  .main-text {
    font-size: 1.2rem; /* Smaller text size on mobile */
  }

  .description {
    font-size: 0.9rem; /* Smaller description text on mobile */
  }
}
