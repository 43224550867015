/* Details.css */
.details {
    padding: 20px;
  }
  
  .text-center {
    text-align: center;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem;
  }
  
  .title {
    font-size: 2rem;
    font-weight: bold;
  }
  
  .detail-description {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .gallery-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .gallery-item {
    flex: 1 1 calc(33.333% - 15px);
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    transition: transform 0.3s ease-in-out;
  }
  
  .gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
  
  .gallery-item:hover {
    transform: scale(1.05);
  }
  
  .gallery-item:hover img {
    transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    .gallery-item {
      flex: 1 1 calc(50% - 15px);
    }
  }
  
  @media (max-width: 576px) {
    .gallery-item {
      flex: 1 1 100%;
    }
  }
  