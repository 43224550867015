
.dropdown-menu-custom {
  position: absolute;
  bottom: 0px; 
  right: 10px; 
}

.dropdown-toggle-custom::after {
  display: none; }

.container {
  max-width: 100%;
  
}

.card {
  display: flex;
  flex-direction: column;
  height: auto; 
}

.card-body {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: black;
}

.card-title {
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 10px;
}

.card-footer {
  margin-top: auto;
  font-size: 14px;
  color: #666;
}

.sort-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sort-text {
  margin-left: 5px;
  color: black;
}


.btn-custom {
  color: white; 
  border: none;
  display: flex;
  align-items: center;
  gap: 5px; 
}


.btn-custom:hover {
  background-color: #0056b3;
  color: white;
}

.btn-spacing {
  margin-right: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .btn-container {
    display: flex; 
    justify-content: center;
    flex-wrap:nowrap; 
    gap: 10px; 
    margin-top: 10px; 
  
     
    
  }

  .btn-spacing {
    margin-right: 0; 
    margin-bottom: 10px; 
  }

  h2 {
   
    text-align: center; 
    margin-bottom: 20px; 
  }

  .row {
    display: flex;
    flex-wrap: wrap; 
  }

  .col-md-4 {
    flex: 1 1 100%; 
    max-width: 100%;
    margin-bottom: 20px; 
  }
}

