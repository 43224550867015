.contact-section {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #ECF0F1;
}

.contact-form {
  width: 100%;
  max-width: 600px;
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.contact-form h2 {
  margin-top: 0;
}

.contact-form label,
.contact-form input,
.contact-form select,
.contact-form textarea {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.contact-form button {
  background-color: #3498DB;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .contact-form {
      width: 90%;
      box-shadow: none;
  }
}

@media (max-width: 576px) {
  .contact-form button {
      font-size: 14px;
      padding: 8px;
  }
}
