/* Ensure this file is correctly linked and styles are applied */
.notification-icon {
    position: relative;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .notification-icon .badge {
    position: absolute;
    top: 0;
    right: 0;
   
    
    background-color: red;
    color: white;
    border-radius: 70%;
    width: 8px;  /* Adjust the size as needed */
    height: 8px; /* Adjust the size as needed */
    font-size: 0.25rem;
    text-align: center;
    line-height: 12px; /* Center the text vertically */
    display: inline-block;
  }
  
  .notification-dropdown {
    position: absolute;
    padding:10px ;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    max-height: 400px;
    overflow-y: auto;
    z-index: 1000;
  }
  